//#region Dirty global variables

var formsToCheckForDirty = [];
var fieldIdsToIgnoreIsDirty = [];

// Setting this to true will disable any kind dirty form message that would stop the user from leaving the page.  This is mainly needed for successful form submits and to override the window unload event when we detect a link clicked
var isDirtyOverridden = false;

// This is the message that will display if a user has made changes then navigates away from the page using the address bar of the browser
var browserDirtyMessage = 'Are you sure you want to leave this page?';

//#endregion

//#region initDirtyForm.  Attaches to the window beforeunload event to check dirty if the user tries to navigate away with the browser

function initDirtyForm() {
  $(window).bind('beforeunload', function () {
    var isDirty = isFormDirty();
    if (isDirty) {
      return browserDirtyMessage;
    }
  });
}

//#endregion

//#region Link Click Handler.  This will trigger a dirty check and notify the user if they click a link within the site

$('a').click(function (e) {
  var href = $(this).attr('href');
  if (href && href != '#' && href != 'javascript:void(0)') {
    var isDirty = isFormDirty();
    if (isDirty) {
      $.SmartMessageBox({
        title: '<i class="fa fa-exclamation-triangle txt-color-yellow"></i>  Are you sure you want to leave this page?',
        content: "You haven't saved your changes yet. Do you want to leave without saving?",
        buttons: '[Yes, leave this page][No, stay on this page]'
      }, function (buttonPressed) {
        if (buttonPressed == 'Yes, leave this page') {
          isDirtyOverridden = true;
          window.location = href;
        }
      });
      e.preventDefault();
    }
  }
  return true;
});

//#endregion

//#region isFormDirty.  Function to check if the form is dirty

function isFormDirty() {
  if (isDirtyOverridden) {
    return false;
  }

  for (var x = 0; x < formsToCheckForDirty.length; x++) {
    var form = formsToCheckForDirty[x];

    for (var i = 0; i < form.elements.length; i++) {
      var element = form.elements[i];

      if ($.inArray(element.id, fieldIdsToIgnoreIsDirty) > -1) {
        return false;
      }

      var type = element.type;
      if (type == "checkbox" || type == "radio") {
        if (element.checked != element.defaultChecked) {
          console.log('Element ' + element.id + ' caused the form to be dirty.  Checked is: ' + element.checked + '. Default checked is: ' + element.defaultChecked);
          return true;
        }
      }
      else if (type == "hidden" || type == "password" ||
               type == "text" || type == "textarea") {
        var currentValue = element.value;
        var defaultValue = element.defaultValue;

        if (currentValue) {
          currentValue = currentValue.replace(/\r\n/g, '\n'); //replace crlf to solve buggy scenario where defaultValue has crlf and value just has lf
        }
        if (defaultValue) {
          defaultValue = defaultValue.replace(/\r\n/g, '\n'); //replace crlf to solve buggy scenario where defaultValue has crlf and value just has lf
        }

        if ($.inArray(element.id, fieldIdsToRemoveMask) > -1) {
          if ($(element).hasClass('percentMask')) {
            currentValue = getUnmaskedNumber(element.value);
          }
          else {
            currentValue = $(element).inputmask('unmaskedvalue') || "";
          }
        }
        if (currentValue != defaultValue) {
          console.log('Element ' + element.id + ' caused the form to be dirty.  Current value is: ' + currentValue + '. Default value is: ' + defaultValue);
          return true;
        }
      }
      else if (type == "select-one" || type == "select-multiple") {
        for (var j = 0; j < element.options.length; j++) {
          if (element.options[j].selected !=
              element.options[j].defaultSelected) {
            console.log('Element ' + element.id + ' caused the form to be dirty.  Option with value ' + element.options[j].value + 'was selected but not was not the default selected option ');
            return true;
          }
        }
      }
    }
  }
  return false;
}

//#endregion

//#region subscribeFormForDirtyDetection.  Sets up the forms that preform dirty detection

function subscribeFormForDirtyDetection(formId) {
  var form = $('#' + formId)[0];
  formsToCheckForDirty.push(form);
}

//#endregion

//#region IsDirtyById.  Call this to setup elements to ignore/un-ignore isDirty check.

function ignoreIsDirtyById() {
  $(arguments).each(function (i, e) {
    var index = fieldIdsToIgnoreIsDirty.indexOf(e);
    if (index < 0) {
      fieldIdsToIgnoreIsDirty.push(e);
    }
  });
}

function checkIsDirtyById() {
  $(arguments).each(function (i, e) {
    var index = fieldIdsToIgnoreIsDirty.indexOf(e);
    if (index >= 0) {
      fieldIdsToIgnoreIsDirty.splice(index, 1);
    }
  });
}

//#endregion IsDirtyById
